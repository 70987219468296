@charset "utf-8";
/*----------reset----------*/

html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
}

body {
    font-family: Avenir,Arial, Hiragino Sans GB, 冬青黑, Microsoft YaHei, 微软雅黑, SimSun, 宋体, Helvetica, Tahoma;
    font-size: .14rem;
    color: #000000;
    word-wrap: break-word;
    overflow: hidden;
}

button, input, select, textarea {
    font: 12px/1.5 Arial, "微软雅黑", Helvetica, Verdana, sans-serif;
    word-wrap: break-word;
    color: #565756;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    font-size: 100%;
}

img {
    border: none;
    border: 0;
    vertical-align: middle;
}

a {
    text-decoration: none;
    outline: none;
}
a{text-decoration:none}
a:link,a:visited{
    text-decoration:none;
}
a:hover{
    text-decoration:underline;
}

p {
    margin: 0;
    padding: 0;
}

input, select, textarea {
    vertical-align: middle;
    *font-size: 100%;
}

input {
    margin: 0;
    outline: none;
    padding: 0;
}

input::-ms-clear {
    display: none;
}

/*--------自定义class--------*/
.text-align-center{
    text-align:center
}
.text-align-right{
    text-align:right
}

.dp-inline-b{
    display: inline-block;
}

.circle{
    border-radius: 50%;
    overflow: hidden;
}

.fll {
    float: left;
}

.flr {
    float: right;
}

.clearfix:after, .clearfix:before {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.cursor-p {
    cursor: pointer;
}
.cursor-no{
    cursor:not-allowed;
}

.ell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
img{
    width:100%;
    height:100%;
}
.btn{
    border-radius: 0;
    padding: 0;
}
.commonW{
    width:960px;
    margin:0 auto;
}

.pad2{
    padding:0 20px;
}


/*----------性能处理----------*/
.udf_scroller {
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);
}